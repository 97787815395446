import React from 'react';
import Image from 'gatsby-image';
import {
  Paper,
  Typography,
  Divider
} from '@material-ui/core';
import {
  makeStyles
} from '@material-ui/styles';
import Figure from '../content/Figure';
import PortableText from '../content/portableText';
import './teamMembers/portableTextStyles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    gap: `${theme.spacing(2)}px`,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  card: {
    background: theme.palette.background.default,
  },
  cardContent: {
    padding: theme.spacing(2),
    width: 400,
    maxWidth: '100vw'
  },
  title: {
    fontWeight: 800,
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  imgWrapper: {
    height: 300,
    overflow: 'hidden',
    position: 'relative'
  }
}))

export default ({teamMembers}) => {
  const classes = useStyles();
  const teamMemberRenderFn = (member, idx) => {
    return (
      <Paper className={classes.card} square key={idx}>
        {member?.mainImage?.asset?.fluid && <div className={classes.imgWrapper}>
          <Image style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            top: `${(member.mainImage?.crop?.top || 0) * -200}%`,
            // left: `${member.mainImage.hotspot.x}%`
          }} fluid={member.mainImage.asset.fluid} />
        </div>}
        <div className={classes.cardContent}>
          <Typography variant='h6' className={classes.title}>{member.name}</Typography>
          {member.startDate && <Typography variant='caption'>{member.position}, {new Date().getFullYear() - new Date(member.startDate).getFullYear()} years</Typography>}
          <Divider className={classes.divider} />
          <PortableText blocks={member._rawBio} />
        </div>
      </Paper>
    )
  };
  return <>
  <div className={classes.root}>
    {teamMembers.filter(m => m._rawBio).map(teamMemberRenderFn)}
  </div>
  <div className={classes.root}>
    {teamMembers.filter(m => !m._rawBio).map(teamMemberRenderFn)}
  </div>
  </>;
}
