import React from "react";
import { Link } from "gatsby";
import * as R from 'ramda';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";


import Layout from "../components/layout";
import Content from '../components/services/content';
import SEO from "../components/seo";
import TeamMembers from '../components/about/teamMembers';
import HeroPage from '../components/services/hero';

const IndexPage = ({
  pageContext: {
    aboutPage
  }
}) => {
  const homePageSeoTitle = "C&R Asphalt Animals |  Lexington's Top Rated Paving Company | Serving Lexington, Louisville, Georgetown, Versailles, Frankfort, Winchester";
  return (
    <Layout>
      <SEO title={homePageSeoTitle} seo={aboutPage.seo?.[0]} />
      <HeroPage
        title={aboutPage.title}
        subtitle={aboutPage.subtitle}
        cta={'Start a project'}
        image={aboutPage.mainImage} />
      <Content content={aboutPage._rawBody} />
      <TeamMembers teamMembers={aboutPage.teamMembers} />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
    }, dispatch),
  }
};

const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage);

export default Index;
